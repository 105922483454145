import {
  faBolt,
  faBook,
  faCalendarAlt,
  faChevronDown,
  faChevronUp,
  faDollarSign,
  faFlag,
  faGlobe,
  faUsers,
  faVideo,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { Menu, MenuButton, MenuLink, MenuList } from '@reach/menu-button';
import React, { useContext, useState } from 'react';
import Logo from 'web/components/Logo';
import themeClasses from 'web/styles/themeClasses.css';
import joinClassNames from 'web/utils/joinClassNames';
import {
  headerContainer,
  headerFakeLinksUser,
  headerLinks,
  headerLinksItem,
  headerLinksMobile,
  headerLogo,
  headerMenu,
  headerMenuDrawer,
  headerMenuDrawerListItem,
  headerMenuDrawerListItemContent,
  headerMenuLinks,
  headerMenuLinkStyled,
  headerMenuSandwichButton,
  headerMenuSandwichButtonIcon,
  headerStickyContainer,
} from './appHeaderMarketing.css';
import { LinkStyled, LinkUnstyled } from './elements';
import inlineButton from './elements/inlineButton.css';
import LinkButton from './elements/LinkButton';
import ListUnstyled from './elements/ListUnstyled';
import sc from './styled';
import UserContext from './UserContext';

const LinksUserLogin = () => {
  const { user, loggingIn } = useContext(UserContext);

  if (loggingIn) {
    return (
      <>
        <li>
          <span className={joinClassNames(headerLinksItem, headerFakeLinksUser)} style={{ width: 44 }}>
            Loading...
          </span>
        </li>
        <li>
          <span className={joinClassNames(headerLinksItem, headerFakeLinksUser)} style={{ width: 112 }}>
            Loading...
          </span>
        </li>
      </>
    );
  }

  if (!user) {
    return (
      <>
        <li>
          <LinkStyled to="/login" className={headerLinksItem}>
            Login
          </LinkStyled>
        </li>
        <li>
          <LinkButton md primary to="/get-started" className={headerLinksItem}>
            Start for free
          </LinkButton>
        </li>
      </>
    );
  }

  return (
    <li>
      <LinkButton md secondary to="/home" style={{ background: '#fff' }} className={headerLinksItem}>
        My account
      </LinkButton>
    </li>
  );
};

const LinksUserLoginMobile = () => {
  const { user, loggingIn } = useContext(UserContext);

  if (loggingIn) {
    return <></>;
  }

  if (!user) {
    return (
      <li>
        <LinkButton sm secondary to="/get-started" style={{ textAlign: 'center' }} className={headerLinksItem}>
          Sign up
        </LinkButton>
      </li>
    );
  }

  return (
    <li>
      <LinkButton
        sm
        secondary
        to="/home"
        style={{ background: '#fff', textAlign: 'center' }}
        className={headerLinksItem}
      >
        My account
      </LinkButton>
    </li>
  );
};

type MenuItem = {
  label: string;
  to: string;
  icon: FontAwesomeIconProps['icon'];
};

const usaCasesItems: MenuItem[] = [
  {
    label: 'Coaches',
    to: '/uses/coaches',
    icon: faFlag,
  },
  {
    label: 'Tutors',
    to: '/uses/tutors',
    icon: faBook,
  },
  {
    label: 'Psychologists',
    to: '/uses/psychologists',
    icon: faBook,
  },
  {
    label: 'Consultants',
    to: '/uses/scheduling-payments-software',
    icon: faDollarSign,
  },
];

const featuresItems: MenuItem[] = [
  {
    label: 'Booking page',
    to: '/features/booking-page',
    icon: faGlobe,
  },
  {
    label: 'Scheduling',
    to: '/features/scheduling',
    icon: faCalendarAlt,
  },
  {
    label: 'Payments',
    to: '/features/payments',
    icon: faDollarSign,
  },
  {
    label: 'Video calls',
    to: '/features/video-calls',
    icon: faVideo,
  },
  {
    label: 'Client management',
    to: '/features/client-management',
    icon: faUsers,
  },
  {
    label: 'Automation',
    to: '/features/automation',
    icon: faBolt,
  },
];

const MenuLinkListItem = ({
  item,
  onClick,
  isDrawer,
}: {
  item: MenuItem;
  onClick?: () => void;
  isDrawer?: boolean;
}) => (
  <MenuLink
    as={LinkStyled}
    className={joinClassNames(headerMenuLinkStyled, isDrawer ? headerMenuDrawerListItemContent : undefined)}
    to={item.to}
    onClick={onClick}
  >
    <FontAwesomeIcon icon={item.icon} fixedWidth className={themeClasses({ marginRight: 1.5 })} aria-hidden={true} />
    {item.label}
  </MenuLink>
);

const LinksMain = () => {
  return (
    <>
      <li>
        <Menu>
          {({ isExpanded }) => (
            <>
              <MenuButton className={joinClassNames(headerLinksItem, inlineButton)}>
                Use cases
                <small aria-hidden={true} className={themeClasses({ marginLeft: 1 })}>
                  <FontAwesomeIcon icon={isExpanded ? faChevronUp : faChevronDown} />
                </small>
              </MenuButton>
              <MenuList className={headerMenu}>
                <div
                  className={themeClasses({
                    display: { all: 'initial', lg: 'flex' },
                    justifyContent: 'flex-end',
                    paddingX: 8,
                    paddingBottom: { all: 8, lg: 0 },
                    alignItems: 'center',
                  })}
                >
                  <h3 className={themeClasses({ margin: 0 })}>
                    All-in-one platform <br className={themeClasses({ display: { all: 'none', lg: 'initial' } })} />
                    for online solopreneurs
                  </h3>
                </div>
                <div className={headerMenuLinks}>
                  {usaCasesItems.map((item) => (
                    <MenuLinkListItem key={item.to} item={item} />
                  ))}
                </div>
                <div
                  className={themeClasses({
                    display: 'flex',
                    justifyContent: 'flex-start',
                    paddingX: 8,
                    paddingTop: { all: 8, lg: 0 },
                    alignItems: 'center',
                  })}
                >
                  <div>
                    Don&apos;t see your use case?
                    <br />
                    <MenuLink as={LinkStyled} className={headerMenuLinkStyled} to="/contact">
                      Let&apos;s chat!
                    </MenuLink>
                  </div>
                </div>
              </MenuList>
            </>
          )}
        </Menu>
      </li>
      <li>
        <Menu>
          {({ isExpanded }) => (
            <>
              <MenuButton className={joinClassNames(headerLinksItem, inlineButton)}>
                Features
                <small aria-hidden={true} className={themeClasses({ marginLeft: 1 })}>
                  <FontAwesomeIcon icon={isExpanded ? faChevronUp : faChevronDown} />
                </small>
              </MenuButton>
              <MenuList className={headerMenu}>
                <div
                  className={themeClasses({
                    display: { all: 'initial', lg: 'flex' },
                    justifyContent: 'flex-end',
                    paddingX: 8,
                    paddingBottom: { all: 8, lg: 0 },
                    alignItems: 'center',
                  })}
                >
                  <h3 className={themeClasses({ margin: 0 })}>
                    Features to start and grow{' '}
                    <br className={themeClasses({ display: { all: 'none', lg: 'initial' } })} />
                    your online business
                  </h3>
                </div>
                <div className={headerMenuLinks}>
                  {featuresItems.map((item) => (
                    <MenuLinkListItem key={item.to} item={item} />
                  ))}
                </div>
                <div
                  className={themeClasses({
                    display: 'flex',
                    justifyContent: 'flex-start',
                    paddingX: 8,
                    paddingTop: { all: 8, lg: 0 },
                    alignItems: 'center',
                  })}
                >
                  <div>
                    Have questions?
                    <br />
                    <MenuLink as={LinkStyled} className={headerMenuLinkStyled} to="/help">
                      Read FAQs
                    </MenuLink>
                  </div>
                </div>
              </MenuList>
            </>
          )}
        </Menu>
      </li>
      <li>
        <LinkStyled to="/pricing" className={headerLinksItem}>
          Pricing
        </LinkStyled>
      </li>
      <li>
        <LinkStyled to="/faq" className={headerLinksItem}>
          FAQ
        </LinkStyled>
      </li>
      <li className={themeClasses({ marginRight: 'auto' })}>
        <LinkStyled to="/contact" className={headerLinksItem}>
          Contact
        </LinkStyled>
      </li>
    </>
  );
};

const Sandwich = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" className={headerMenuSandwichButtonIcon}>
    <path d="M19 11H1a1 1 0 110-2h18a1 1 0 110 2zm0-7H1a1 1 0 110-2h18a1 1 0 110 2zm0 14H1a1 1 0 110-2h18a1 1 0 110 2z"></path>
  </svg>
);

const Cross = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" className={headerMenuSandwichButtonIcon}>
    <path d="M11.414 10l6.293-6.293a1 1 0 00-1.414-1.414L10 8.586 3.707 2.293a1 1 0 00-1.414 1.414L8.586 10l-6.293 6.293a1 1 0 001.414 1.414L10 11.414l6.293 6.293a.996.996 0 001.414 0 1 1 0 000-1.414L11.414 10z"></path>
  </svg>
);

const LinksDrawer = ({ onClick }: { onClick: () => void }) => {
  const { user, loggingIn } = useContext(UserContext);

  return (
    <ListUnstyled>
      <li className={headerMenuDrawerListItem}>
        <Menu>
          {({ isExpanded }) => (
            <>
              <MenuButton className={joinClassNames(headerMenuDrawerListItemContent, inlineButton)}>
                Use cases
                <small aria-hidden={true} className={themeClasses({ marginLeft: 1 })}>
                  <FontAwesomeIcon icon={isExpanded ? faChevronUp : faChevronDown} />
                </small>
              </MenuButton>
              <MenuList portal={false}>
                {usaCasesItems.map((item) => (
                  <MenuLinkListItem key={item.to} item={item} onClick={onClick} isDrawer />
                ))}
              </MenuList>
            </>
          )}
        </Menu>
      </li>
      <li className={headerMenuDrawerListItem}>
        <Menu>
          {({ isExpanded }) => (
            <>
              <MenuButton className={joinClassNames(headerMenuDrawerListItemContent, inlineButton)}>
                Features
                <small aria-hidden={true} className={themeClasses({ marginLeft: 1 })}>
                  <FontAwesomeIcon icon={isExpanded ? faChevronUp : faChevronDown} />
                </small>
              </MenuButton>
              <MenuList portal={false}>
                {featuresItems.map((item) => (
                  <MenuLinkListItem key={item.to} item={item} onClick={onClick} isDrawer />
                ))}
              </MenuList>
            </>
          )}
        </Menu>
      </li>
      <li className={headerMenuDrawerListItem}>
        <LinkStyled to="/pricing" onClick={onClick} className={headerMenuDrawerListItemContent}>
          Pricing
        </LinkStyled>
      </li>
      <li className={headerMenuDrawerListItem}>
        <LinkStyled to="/faq" onClick={onClick} className={headerMenuDrawerListItemContent}>
          FAQ
        </LinkStyled>
      </li>
      <li className={headerMenuDrawerListItem}>
        <LinkStyled to="/contact" onClick={onClick} className={headerMenuDrawerListItemContent}>
          Contact
        </LinkStyled>
      </li>
      {!loggingIn && (
        <li className={headerMenuDrawerListItem}>
          {!user && (
            <LinkStyled to="/login" onClick={onClick} className={headerMenuDrawerListItemContent}>
              Login
            </LinkStyled>
          )}
          {user && (
            <LinkStyled to="/logout" onClick={onClick} className={headerMenuDrawerListItemContent}>
              Logout
            </LinkStyled>
          )}
        </li>
      )}
    </ListUnstyled>
  );
};

const AppHeaderStickyContainer = sc.div(headerStickyContainer);

const logoColor = '#FF6771';
const AppHeaderMarketing = ({ logoLook, zeroPadding }: { logoLook: 'gradient' | 'solid'; zeroPadding?: boolean }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  return (
    <header>
      <nav className={headerContainer} style={zeroPadding ? { padding: 0 } : {}}>
        <LinkUnstyled to="/">
          <Logo
            className={headerLogo}
            {...(logoLook === 'gradient' ? { gradient: { from: '#ffc79c', to: '#ff6771' } } : { color: logoColor })}
          />
        </LinkUnstyled>
        <ul className={headerLinks}>
          <LinksMain />
          <LinksUserLogin />
        </ul>
        <ul className={headerLinksMobile}>
          <LinksUserLoginMobile />
          <li>
            <button
              type="button"
              className={headerMenuSandwichButton}
              onClick={() => setDrawerOpen((prev) => !prev)}
              title="Menu"
            >
              {!drawerOpen ? <Sandwich /> : <Cross />}
            </button>
          </li>
        </ul>
      </nav>
      {drawerOpen && (
        <nav className={headerMenuDrawer}>
          <LinksDrawer onClick={() => setDrawerOpen(false)} />
        </nav>
      )}
    </header>
  );
};

export { AppHeaderStickyContainer };
export default AppHeaderMarketing;
